import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Disclosure, Transition } from "@headlessui/react";
import { FiChevronDown, FiCoffee, FiUsers } from "react-icons/fi";

const FAQSection = () => {
  const [activeTab, setActiveTab] = useState("gastro");

  const faqsGastro = [
    {
      question: "Wie kann ich Gastroempire für mein Restaurant nutzen?",
      answer:
        "Um Gastroempire zu nutzen, kontaktieren Sie uns für eine individuelle Beratung. Wir erstellen einen maßgeschneiderten Plan für Ihr Restaurant und bieten eine einmonatige kostenlose Testphase an. Nach der Einrichtung können Sie sofort mit der Verwaltung Ihres Online-Bestellsystems beginnen.",
    },
    {
      question: "Welche Zahlungsmethoden unterstützt Gastroempire?",
      answer:
        "Gastroempire unterstützt eine Vielzahl von Zahlungsmethoden, darunter Kreditkarten (Visa, Mastercard, American Express), PayPal, Apple Pay, Google Pay und Sofortüberweisung. Wir arbeiten kontinuierlich daran, weitere Zahlungsoptionen hinzuzufügen, um den Bedürfnissen Ihrer Kunden gerecht zu werden.",
    },
    {
      question:
        "Wie integriert sich Gastroempire in mein bestehendes Kassensystem?",
      answer:
        "Gastroempire bietet flexible Integrationsmöglichkeiten und wir können auch eine kundenspezifische Integrationen entwickeln. Unser Team arbeitet eng mit Ihnen zusammen, um eine nahtlose Verbindung zwischen Gastroempire und Ihrem bestehenden System sicherzustellen.",
    },
  ];

  const faqsCustomer = [
    {
      question: "Wie können meine Kunden Bestellungen aufgeben?",
      answer:
        "Ihre Kunden können ganz einfach über Ihre Gastroempire-Webseite bestellen. Sie wählen die gewünschten Gerichte aus, fügen sie zum Warenkorb hinzu und schließen die Bestellung ab. Das System bietet eine intuitive Benutzeroberfläche mit Filtermöglichkeiten.",
    },
    {
      question: "Wie können meine Kunden ihre Bestellung verfolgen?",
      answer:
        "Nach der Bestellung erhalten Kunden eine Bestätigungs-E-Mail mit einer einzigartigen Bestellnummer und dem Bestellstatus.",
    },
    {
      question:
        "Bietet Gastroempire Optionen für Allergien und Ernährungsvorlieben?",
      answer:
        "Ja, Gastroempire ermöglicht es Ihnen, detaillierte Informationen zu Allergenen anzugeben. Zudem können individuelle Anmerkungen zu Bestellungen hinzugefügt werden.",
    },
  ];

  const TabButton = ({ id, icon: Icon, label }) => (
    <button
      className={`flex items-center space-x-2 px-4 py-2 rounded-full transition-all ${
        activeTab === id
          ? "bg-blue-600 text-white shadow-lg"
          : "bg-white text-gray-600 hover:bg-gray-100"
      }`}
      onClick={() => setActiveTab(id)}
    >
      <Icon className="w-5 h-5" />
      <span>{label}</span>
    </button>
  );

  const FAQItem = ({ item }) => (
    <Disclosure as="div" className="mt-4">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-between w-full px-4 py-3 text-lg font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
            <span>{item.question}</span>
            <FiChevronDown
              className={`${
                open ? "transform rotate-180" : ""
              } w-5 h-5 text-blue-500`}
            />
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-600">
              {item.answer}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );

  return (
    <section className="py-16 bg-gradient-to-br from-blue-50 to-indigo-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 text-gray-800">
          Häufig gestellte Fragen
        </h2>
        <div className="flex justify-center space-x-4 mb-8">
          <TabButton id="gastro" icon={FiCoffee} label="Für Gastronomen" />
          <TabButton id="customer" icon={FiUsers} label="Für Kunden" />
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-3xl mx-auto bg-white rounded-xl shadow-xl overflow-hidden"
        >
          <div className="p-8">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                {activeTab === "gastro" ? (
                  <>
                    <h3 className="text-2xl font-semibold mb-6 text-gray-800">
                      Für Gastronomen
                    </h3>
                    {faqsGastro.map((item, index) => (
                      <FAQItem key={index} item={item} />
                    ))}
                  </>
                ) : (
                  <>
                    <h3 className="text-2xl font-semibold mb-6 text-gray-800">
                      Für Kunden
                    </h3>
                    {faqsCustomer.map((item, index) => (
                      <FAQItem key={index} item={item} />
                    ))}
                  </>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default FAQSection;
