import React from "react";

const Footer = ({ setShowImprint }) => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h3 className="text-xl font-bold mb-2">Gastroempire</h3>
            <p className="text-sm">
              Ihre All-in-One-Lösung für Restaurantmanagement
            </p>
          </div>
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h4 className="text-lg font-semibold mb-2">Kontakt</h4>
            <p className="text-sm">Email: info@wolf-code.de</p>
            <p className="text-sm">Tel: +49 123 456789</p>
          </div>
          <div className="w-full md:w-1/3">
            <button
              onClick={() => setShowImprint(true)}
              className="text-sm underline hover:text-blue-300 transition duration-300"
            >
              Impressum & Datenschutz
            </button>
          </div>
        </div>
        <div className="mt-8 text-center text-sm">
          © {new Date().getFullYear()} Gastroempire. Alle Rechte vorbehalten.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
