import React from "react";
import { motion } from "framer-motion";
import FeatureSection from "./FeatureSection";
import Footer from "./Footer";
import ImprintAndPrivacy from "./ImprintAndPrivacy";
import FAQSection from "./FAQSection";

const LandingPage = () => {
  const [showImprint, setShowImprint] = React.useState(false);

  const features = [
    {
      title: "Umfassendes Bestellsystem",
      description:
        "Erstellen und verwalten Sie Produkte, Kategorien und Sparmenüs mit Bildern und detaillierten Informationen.",
      imageSrc: "/images/order-system.jpg",
    },
    {
      title: "Flexible Bestelloptionen",
      description:
        "Bieten Sie Lieferung und Abholung mit anpassbaren Liefergebieten und Vorbestellungen.",
      imageSrc: "/images/delivery-options.jpg",
    },
    {
      title: "Automatisierte Bestellverarbeitung",
      description:
        "MQTT-Integration für Echtzeit-Benachrichtigungen und automatischen Bestellausdruck.",
      imageSrc: "/images/order-processing.jpg",
    },
    {
      title: "Umfangreiche Verwaltungstools",
      description:
        "Einfache Verwaltung von Menüs, Bestellungen, Öffnungszeiten und mehr.",
      imageSrc: "/images/management-tools.jpg",
    },
    {
      title: "Kundenfreundliche Funktionen",
      description:
        "Warenkorb-Verwaltung, Lieferverfügbarkeitsprüfung und E-Mail-Benachrichtigungen.",
      imageSrc: "/images/customer-service.jpg",
    },
    {
      title: "Analysen und Statistiken",
      description:
        "Erhalten Sie Einblicke in Umsätze, beliebte Produkte und Bestelltrends.",
      imageSrc: "/images/analytics.jpg",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white overflow-x-hidden">
      <header className="bg-white shadow-md py-4 px-6 sticky top-0 z-50">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl md:text-3xl font-bold text-blue-600">
            Gastroempire
          </h1>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <a
                  href="#features"
                  className="text-gray-600 hover:text-blue-600"
                >
                  Features
                </a>
              </li>
              <li>
                <a href="#faq" className="text-gray-600 hover:text-blue-600">
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="text-gray-600 hover:text-blue-600"
                >
                  Kontakt
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main>
        <section className="relative py-20 text-center">
          <div className="absolute inset-0 z-0">
            <img
              src="/images/hero-background.jpg"
              alt="Modern restaurant interior"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 text-white leading-tight">
              Revolutionieren Sie Ihr Restaurantgeschäft
            </h2>
            <p className="text-lg sm:text-xl mb-8 text-gray-200">
              Mit Gastroempire - Ihrer All-in-One-Lösung für Online-Bestellungen
              in der Gastronomie
            </p>
            <motion.a
              href="#contact"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-block bg-blue-600 text-white px-6 sm:px-8 py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-blue-700 transition duration-300"
            >
              Jetzt starten
            </motion.a>
          </div>
        </section>

        <div className="bg-yellow-100 text-center py-4 px-6">
          <p className="text-yellow-800 font-semibold">
            Hinweis: Diese Website befindet sich derzeit im Aufbau. Unsere App
            ist in aktiver Entwicklung.
          </p>
        </div>

        <section id="features" className="py-20 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">
              Unsere Features
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <FeatureSection key={index} {...feature} />
              ))}
            </div>
          </div>
        </section>

        <section className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">
              Gastroempire in Aktion
            </h2>
            <div className="flex flex-wrap justify-center items-center">
              <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8">
                <img
                  src="/images/mobile-app-preview.jpg"
                  alt="Gastroempire mobile app"
                  className="w-full h-auto shadow-lg rounded-lg"
                />
              </div>
              <div className="w-full sm:w-2/3 md:w-1/2 lg:w-1/3 px-4 mb-8">
                <img
                  src="/images/tablet-app-preview.jpg"
                  alt="Gastroempire tablet app"
                  className="w-full h-auto shadow-lg rounded-lg"
                />
              </div>
              <div className="w-full md:w-2/3 lg:w-1/2 px-4 mb-8">
                <img
                  src="/images/desktop-app-preview.jpg"
                  alt="Gastroempire desktop app"
                  className="w-full h-auto shadow-lg rounded-lg"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className="py-20 bg-gray-100">
          <FAQSection />
        </section>

        <section id="contact" className="py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-6">
              Kontaktieren Sie uns
            </h2>
            <p className="text-center text-lg mb-8">
              Für Anfragen können Sie uns gerne eine E-Mail schreiben an:{" "}
              <a
                href="mailto:info@wolf-code.de"
                className="text-blue-600 hover:underline"
              >
                info@wolf-code.de
              </a>
            </p>
            {/* Kontaktformular - Auskommentiert für zukünftige Implementierung
            <ContactForm />
            */}
          </div>
        </section>
      </main>

      <Footer setShowImprint={setShowImprint} />

      {showImprint && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-2xl max-h-[80vh] overflow-y-auto">
            <ImprintAndPrivacy />
            <button
              onClick={() => setShowImprint(false)}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
            >
              Schließen
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
